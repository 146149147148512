import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Frequently Asked Questions (FAQ)">
    <section className="section-faq">

      <div className="review-section">
        <div className="highlight">
          <strong>मैं किस प्रकार से सहायता कर सकता हुँ ?</strong>
          <div className="arrow-box-top">
            आप हमारी सहायता, इस योजना को अधिक से अधिक बहन/बंधुओं तक पहुँचाने में कर सकते है |<br/>
            1. एंड्राइड एप्प को डाउनलोड कर <a className="fa fa-android" href="https://play.google.com/store/apps/details?id=me.parakh.edu"> Play Store&nbsp;</a><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span> पर अपने रिव्यु कमेंट्स, स्टार रेटिंग एवं
            

            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="-5 -3 30 18" className="u7 g-plus-1">
              <path d="m13,3-1,0 0,2-2,0 0,1 2,0 0,2 1,0 0-2 2,0 0-1-2,0zm3-1 0,1 1.525,0 0,7 1.475,0 0-9z"></path>
              <path d="m5.286,4.594c.6738-.7371 .4033-1.8668 .0644-2.6978-.3285-.8508-1.2405-1.6446-2.1986-1.2906-1.0081,.2923-1.1732,1.5074-.9326,2.3834 .2018,1.0002 .9514,2.1602 2.1087,2.0389 .3526-.0415 .6989-.1886 .9582-.4338zm-1.07,2.994c-.9808,.1331-2.2146,.2197-2.7558,1.1827-.4634,.903 .1368,2.0029 1.0462,2.3257 1.0948,.423 2.4643,.5029 3.4766-.1714 .7996-.5457 .782-1.7676 .0953-2.3999-.4467-.4296-.9761-.9594-1.6354-.928l-.1604-.0062-.0665-.0029zm2.08-6.813c.9768,.8909 1.1759,2.5831 .202,3.5514-.4793,.4835-1.6627,1.1022-.9829,1.9012 .8682,.745 2.0921,1.3705 2.1724,2.6504 .1322,1.4396-1.152,2.5382-2.4389,2.8743-1.3767,.3557-2.9341,.3999-4.2257-.2652-.9283-.463-1.2487-1.6423-.8776-2.5713 .4529-1.2574 1.8947-1.6648 3.0933-1.8019 .455-.0565 1.0058,.0692 .574-.4763-.3008-.5051 .4956-1.4305-.4106-1.1547-1.3817,.0793-2.7503-1.0582-2.719-2.482-.0055-1.4944 1.3204-2.6506 2.7222-2.8791 1.2321-.1995 2.4871-.0819 3.7303-.1139 .2139,.0229 .6535-.0445 .736,.031-.2764,.2346-.5192,.5532-.8164,.736-.253,0-.506,0-.7591,0z"></path>
              <path d="m5.286,4.594c.6738-.7371 .4033-1.8668 .0644-2.6978-.3285-.8508-1.2405-1.6446-2.1986-1.2906-1.0081,.2923-1.1732,1.5074-.9326,2.3834 .2018,1.0002 .9514,2.1602 2.1087,2.0389 .3526-.0415 .6989-.1886 .9582-.4338zm-1.07,2.994c-.9808,.1331-2.2146,.2197-2.7558,1.1827-.4634,.903 .1368,2.0029 1.0462,2.3257 1.0948,.423 2.4643,.5029 3.4766-.1714 .7996-.5457 .782-1.7676 .0953-2.3999-.4467-.4296-.9761-.9594-1.6354-.928l-.1604-.0062-.0665-.0029zm2.08-6.813c.9768,.8909 1.1759,2.5831 .202,3.5514-.4793,.4835-1.6627,1.1022-.9829,1.9012 .8682,.745 2.0921,1.3705 2.1724,2.6504 .1322,1.4396-1.152,2.5382-2.4389,2.8743-1.3767,.3557-2.9341,.3999-4.2257-.2652-.9283-.463-1.2487-1.6423-.8776-2.5713 .4529-1.2574 1.8947-1.6648 3.0933-1.8019 .455-.0565 1.0058,.0692 .574-.4763-.3008-.5051 .4956-1.4305-.4106-1.1547-1.3817,.0793-2.7503-1.0582-2.719-2.482-.0055-1.4944 1.3204-2.6506 2.7222-2.8791 1.2321-.1995 2.4871-.0819 3.7303-.1139 .2139,.0229 .6535-.0445 .736,.031-.2764,.2346-.5192,.5532-.8164,.736-.253,0-.506,0-.7591,0z"></path>
            </svg> +1 देकर |<br/>
            2. योजना के <a className="fa fa-facebook" href="https://www.facebook.com/pages/Parakhme/633229686711356"> Facebook</a> पेज को <span className="fa fa-thumbs-o-up"></span> like करे एवं अन्य बहन/बंधुओं को पेज पर invite करे |<br/>
            3. योजना के <a className="fa fa-google-plus" href="https://plus.google.com/+ParakhMe1"> Google+</a> पेज को फॉलो(follow) करे साथ ही पोस्ट को like करे |<br/>
            4. योजना के <a className="fa fa-youtube" href="https://www.youtube.com/channel/UCe44m-DpRdQMwC_qVZCg2TQ"> YouTube</a> चैनल को सब्सक्राइब(subscribe) करे |<br/>
            5. योजना के <a className="fa fa-twitter" href="https://twitter.com/parakhme"> Twitter</a> अकाउंट को फॉलो(follow) करे |<br/>
            6. और सब से प्रभावशाली रूप, आप स्वयं अपने मित्रो को इसके बारे में फ़ोन, sms, whatsapp के माध्यम से बताये |<br/>
            सहयोग के लिए धन्यवाद !!
          </div>
        </div>
        <div className="highlight">
          <strong>नि:शुल्क सेवा कैसे ?</strong> <a className="powerby" href="http://goo.gl/fPI8dX">from play store</a>
          <p>
            चुकी यह एक नि:शुल्क सेवा है, और एप्प डाउनलोड भी फ्री है, फिर आप निम्न कार्यो मे लगी लागत कहा से प्राप्त करते है |<br/>
            1. वेबसाइट एवं मोबाइल एप्प को बनाने एवं रखरखाव में होने वाला खर्च |<br/>
            2. गत चार वर्षों से चल रही वेबसाइट एवं तत्कालीन मोबाइल एप्प की सर्वर या होस्टिंग में होने वाला निरंतर खर्च |<br/>
            धन्यवाद,<br/>
            अतीत
          </p>
          <div className="arrow-box-top">
            सर्वप्रथम अतीत जी आपका एप्प इंस्टॉल करने के लिए धन्यवाद!<br/>
            मैं विगत १० वर्षों से सॉफ्टवेयर इंडस्ट्री में एक एप्लीकेशन डेवलपर के रूप मे कार्यरत हु | इन वर्षो मे मुझे end-to-end सॉफ्टवेयर बनाने का अनुभव प्राप्त हुआ और साथ ही सीखने का यह क्रम निरंतर चल रहा है ।<br/>
            मैं हर महीने मे अपने वेतन का एक छोटा सा भाग को मेरी कंपनी एवं NGO के माध्यम से सामाजिक कार्यो में दान करता आ रहा हु| फिर मन में एक विचार आया की, यह सब पर्याप्त नहीं है, कुछ और भी करना चाहिए,<br/>
            तब कुछ शिक्षक भाई बंधुओ के साथ मिलकर, अपने सॉफ्टवेयर डेवलपमेंट के अनुभव का उपयोग करते हुए, ऑनलाइन एजुकेशन पोर्टल एवं एप्प का निर्माण किया। साथ ही निरंतर मैं अपने अतिरिक्त समय में टूल को सरल बनाने एवं नयी सुविधाओ को जोड़ने की दृष्टि से, इन्हे अपडेट करता रहता हूँ ।<br/>

            इस प्रकार वेबसाइट एवं एप्प का निर्माण और रखरखाव के लिए खर्च शून्य मात्र है ।<br/>

            और सर्वर/होस्टिंग के खर्च का भुगतान, मैं स्वयं एवं मेरे मित्रो से प्राप्त अनुदान द्वारा करता हूँ |<br/>
            इस प्रकार फ्री एप्प और बिना विज्ञापन के भी, यह सुविधा पूर्णतः निशुल्क है, केवल शिक्षक उपयोगकर्ता को अपने समय एवं थोड़ा इंटरनेट का खर्च करना होता है |
          </div>
        </div>
        <div className="highlight">
          <strong>एंड्राइड में किस प्रकार से हिंदी की-बोर्ड लाया जाता है |</strong>
          <div className="arrow-box-top">
            प्ले-स्टोर से गूगल हिंदी इनपुट एप्प डाउनलोड कर इंस्टॉल करें । एवं उपयोग की विधि को जानने के लिए प्ले-स्टोर पर एप्प के साथ दिए गए वीडियो को देखे |
            <br/>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.inputmethod.hindi">Google Hindi Input</a><br/>
            <a target="_blank" href="https://www.youtube.com/watch?v=otCe903imh0">YouTube - Google Hindi Input demo</a>
          </div>
        </div>
        <div className="highlight">
          <a target="_blank" href="http://get.adobe.com/reader/">Acrobat Reader इन्स्टाल करें</a><br/>
          <a target="_blank" href="http://164.100.96.10/gacdn/Common/Downloads/hindi_IME_setup5.zip">Unicode हिंदी इन्स्टाल करें</a><br/>
          <a target="_blank" href="http://translate.google.co.in/">Google translator</a><br/>
          <a target="_blank" href="http://www.google.com/transliterate">Google transliteration</a><br/>
        </div>
      </div>



    </section>
  </Layout>
);

export default Page;

/*
<!--
            ion-ios-star-outline
            <span className="icon ion-social-googleplus-outline"></span> -->
 */